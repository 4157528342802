import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import Button from "react-bootstrap/Button";
import PricingCardLarge from "../../components/pricing-card-large";

const BusinessMotPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "17.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				aliceS: file(relativePath: { eq: "testimonials/Dr-Alice-Scott.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				kolos: file(relativePath: { eq: "testimonials/Kolos-Tapler.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				richardW: file(
					relativePath: { eq: "testimonials/Richard Worsfold.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				elizabethK: file(
					relativePath: { eq: "testimonials/elizabeth-keates.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				jessC: file(relativePath: { eq: "testimonials/Jessica Cox.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const aliceS = data.aliceS.childImageSharp.gatsbyImageData;
	const richardW = data.richardW.childImageSharp.gatsbyImageData;
	const elizabethK = data.elizabethK.childImageSharp.gatsbyImageData;
	const title = "Problem Buster Package";
	const description =
		"Are you ready to tackle a pressing issue or question in your small business or practice?";
	const heroImg = data.heroImg.childImageSharp.fluid;
	const kolos = data.kolos.childImageSharp.gatsbyImageData;
	const jessC = data.jessC.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Problem Buster Package",
				item: {
					url: `${siteUrl}/problem-buster-package`,
					id: `${siteUrl}/problem-buster-package`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/problem-buster-package`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Problem Buster Package with Jason Cornes",
						},
					],
				}}
			/>
			<Hero
				isDarken={true}
				backgroundImage={heroImg}
				title="Problem Buster Package"
				description1="Are you ready to tackle a pressing issue or question in your small business or practice? "
				description2=""
			/>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								Is a single challenge or question holding back your business
								success?{" "}
							</h2>
							<p>
								Just as a ‘spanner in the works’ can slow or even stop an entire
								machine, one unresolved business problem can hinder you or your
								company’s progress. Whether it is pricing or profitability,
								work-life balance, leadership, mindset, time management, a team
								or colleague issue, a marketing dilemma or a strategic
								challenge, it's crucial to address it head-on to prevent further
								complications, feel energised, seize opportunities and unblock
								progress.
							</p>
							<h3 className="pt-3">What is the Problem Buster Package? </h3>
							<p>
								My "Problem Buster Package" is meticulously designed to identify
								and tackle your most pressing business challenge or question.
								With a series of three intensive sessions totalling four hours,
								plus a complimentary follow-up, we will dive deep into the
								specifics of your concern or question, explore innovative
								solutions including those outside the conventional framework and
								develop a clear, actionable plan to navigate your way to
								success.{" "}
							</p>

							<ul>
								<li>
									<strong>Targeted Problem-Solving:</strong> Directly address
									the specific challenge preventing you or your business from
									reaching its full potential.
								</li>
								<li>
									<strong>Creative and Critical Thinking:</strong> We push the
									boundaries of standard solutions to uncover unique and
									effective strategies.
								</li>
								<li>
									<strong>Actionable Plans:</strong> Leave each session with
									clear steps and strategies to implement immediately.
								</li>
							</ul>
							<h3 className="pt-3">Why Choose the Problem Buster Package?</h3>
							<p>
								Choosing my "Problem Buster Package" means opting for rapid,
								focused intervention designed to yield quick results. Here is
								what makes this package ideal for any business or practice
								leader who is feeling stuck:
							</p>

							<ul>
								<li>
									<strong>Efficiency:</strong> Quick, intensive sessions
									minimize downtime and maximize results.
								</li>
								<li>
									<strong>Support:</strong> Ongoing homework and support between
									sessions ensure continuous progress and accountability.
								</li>
								<li>
									<strong>Flexibility:</strong> This package can be a standalone
									option or a precursor to the more extensive 6 or 12 Months
									Business & Executive Coaching program.
								</li>
							</ul>
							<h3 className="pt-3">Who Needs This Package?</h3>
							<p>
								‘Single decision maker’ business or practice owners and who
								recognize a specific barrier to their success will find this
								package invaluable. It is perfect for those who:
							</p>

							<ul>
								<li>
									Are experiencing a clear and identifiable challenge or
									question that needs immediate resolution.
								</li>
								<li>
									Are impatient for positive change and are prepared to do
									things differently to get a different result.
								</li>
								<li>
									Are prepared to engage deeply and take swift action based on
									expert suggestions.
								</li>
								<li>
									Would like to experience my services before moving onto 6
									months or 12 months of business and executive coaching.
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Your Pathway to Clear Solutions</h2>
							<p>
								During our sessions, we will not only define the nature of your
								business challenge but also:
							</p>
							<ul>
								<li>Explore various strategies and their potential impacts.</li>
								<li>
									Develop a comprehensive, executable plan tailored to your
									specific situation.
								</li>
								<li>
									Set milestones for implementation and success, ensuring you
									have a roadmap to follow post-consultation.
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">What do my clients say?</h2>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container>
					<HorizontalTestimonial
						review="Having launched my business, the enormity of the ongoing management, growth and business development felt overwhelming. Starting coaching with Jason is the best business decision I have made so far. His calm, methodical and analytical approach has been invaluable. Sometimes it is easy to get caught up in a situation yourself, making you prone to emotional decision making, and I have found that Jason has been a good sounding board. He is encouraging to help you to push on with what your ultimate goals are. I am still having regular business coaching with Jason."
						name="Dr Alice Scott. General Practitioner and Menopause Specialist. Essex Private Doctors."
						linkedIn="https://www.linkedin.com/in/dr-alice-scott-45b268118/"
						profilePic={aliceS}
					/>
					<HorizontalTestimonial
						review="I have been working with Jason Cornes, a business coach, over the past six months. At the start of the process, I was time-poor and trying to juggle too many plates, some of which were not making any money. During the coaching process, Jason has helped me to identify my target market, develop client avatars, and refine my services to align with the needs of these client types. I have found the whole process extremely useful, and having Jason as a sounding board for ideas has been invaluable. Jason frequently pushes me on my decision-making and ensures I have looked at all of the options before making an informed decision. If you are looking for a business coach who is straight-talking and not afraid to point out areas of development and push you to improve, Jason is your coach!"
						name="Richard Worsfold. Branding Box."
						linkedIn="https://www.linkedin.com/in/richardjamesworsfold/"
						profilePic={richardW}
					/>
					<HorizontalTestimonial
						review="Astonishingly brilliant! Two rather powerful words to describe how I felt about Jason Cornes after our meeting. To cut to the chase, which is exactly what Jason did with a business growth issue I had been stuck with for quite a while, I found the session inspirational and I finally have a road map forward. Through hard-hitting truths Jason was able to show me exactly where I was stuck and why. He guided me towards a clear plan of action and together we identified the areas where I feel I need support to enable me to achieve the goal I have been trying to reach for a number of years now. Moments felt productively uncomfortable as we addressed my comfort zone and looked at my self-limiting and destructive cycles, but before my heart rate became too high, Jason quickly showed me ways to reach a solution and above all he has laid a plan to give me the support I need to let go and actually move forward. With Jason coaching from the side-line, I truly can’t wait to report back on where my business will be in 12 months."
						name="Elizabeth Keates Film & Photography"
						linkedIn="https://www.linkedin.com/in/elizabeth-keates-film-photography"
						profilePic={elizabethK}
					/>
				</Container>
			</section>
			<section className="py-3">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Let's Get Started!</h2>
							<p>
								Are you ready to tackle your business challenge without delay?
								With the "Problem Buster Package" you can swiftly transition
								from identifying a problem to implementing a solution. Book your
								first session today and take the first step towards unblocking
								your path to success.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			{/* <section className="py-7" style={{ backgroundColor: "#FBFBFB" }}> */}
			<section className="py-7">
				<Container>
					<Row>
						<Col>
							<h2 className="text-center text-primary pb-7">
								Problem Buster Package
							</h2>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<PricingCardLarge
							title="Problem Buster"
							subText="Let’s tackle a business challenge or opportunity head-on"
							price={
								<span>
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										UK £995
									</span>{" "}
									<span className="text-primary font-weight-bold">+ VAT</span>
									<br />
									<span
										style={{ fontSize: "130%" }}
										className="text-primary font-weight-bold"
									>
										USA $1500
									</span>{" "}
								</span>
							}
							features={[
								"3 sessions (4 hours) + complimentary follow up session",

								"Targeted problem-solving to address your specific issue",

								"Explore a range of options including ‘outside the box’",
								"Develop a clear, actionable resolution or plan",

								"‘Homework’ and support between sessions as required",
								"A standalone option or continue to 6 or 12 Months Business & Executive Coaching",
							]}
						/>
					</Row>
				</Container>
			</section>

			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2>Ready to Transform Challenges into Opportunities?</h2>
							<p>
								My business thrives on helping you overcome hurdles. Do not let
								problems hold you back any longer. Contact me now to discuss how
								the "Problem Buster Package" can make a significant difference
								in your business journey.
							</p>
						</Col>
					</Row>
					<Row>
						<Col className="text-center pt-3">
							<Button
								variant="outline-light"
								size="lg"
								className="cta-btn cta-btn-gtm mr-lg-4 mb-4 mb-md-0"
								as={Link}
								to="/contact-us"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};
export default BusinessMotPage;
